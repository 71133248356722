import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';

const InstagramSyncStatus = () => {
  const [syncStatus, setSyncStatus] = useState('Syncing...');
  const navigate = useNavigate();

  useEffect(() => {
    console.log("InstagramSyncStatus mounted");

    let isMounted = true;

    const syncData = async () => {
      console.log("syncData function called");

      const selectedData = JSON.parse(localStorage.getItem('selectedData')) || [];
      const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

      try {
        const response = await fetch(`${API_URL}/sync_data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: 'test_user',
            selectedData: selectedData,
          }),
        });

        console.log("Sync Data response:", response);

        if (response.ok && isMounted) {
          const dateTime = new Date().toLocaleString();
          console.log("Setting Instagram Synced:", 'true');
          console.log("Setting Connected DateTime:", dateTime);
          localStorage.setItem('isInstagramSynced', 'true'); // Ensure this is being set
          localStorage.setItem('connectedDateTime', dateTime);
          setSyncStatus('Completed');
          setTimeout(() => {
            navigate('/integration');
          }, 2000);
        } else if (isMounted) {
          setSyncStatus('Failed');
          localStorage.removeItem('isInstagramSynced');
          localStorage.removeItem('connectedDateTime');
        }
      } catch (error) {
        console.error('Error syncing data:', error);
        if (isMounted) {
          setSyncStatus('Failed');
          localStorage.removeItem('isInstagramSynced');
          localStorage.removeItem('connectedDateTime');
        }
      }
    };

    if (!localStorage.getItem('isInstagramSynced')) {
      syncData();
    } else {
      setSyncStatus('Already Synced');
      setTimeout(() => {
        navigate('/integration');
      }, 2000);
    }

    return () => {
      console.log("InstagramSyncStatus unmounted");
      isMounted = false;
    };
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex flex-col items-center justify-center text-white">
      <Navbar />
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg text-center">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Data Sync Status</h2>
        <p className="text-xl text-gray-800">{syncStatus}</p>
        {syncStatus === 'Failed' && (
          <p className="text-red-600 mt-4">There was an error syncing your data. Please try again.</p>
        )}
      </div>
    </div>
  );
};

export default InstagramSyncStatus;
