import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div>
      <style>{`
        .navbar {
          position: fixed;
          top: 20px; /* Move the navbar down */
          left: 0;
          right: 0;
          z-index: 50; /* This ensures the navbar stays on top of the content */
          height: 60px; /* Reduce navbar height */
        }
      `}</style>

      <nav
        className="navbar fixed top-8 left-0 right-0 z-50 px-8 py-4 flex justify-between items-center shadow-lg"
        style={{
          backgroundColor: 'transparent',
          maxWidth: '1200px',
          margin: '0 auto',
        }}
      >
        <div className="flex items-center space-x-4"> {/* Adjusted space between logo and text */}
          {/* Add the logo image here */}
          <img
            src="/images/dougianalytics_logo2.png" // Adjust the path to match your logo location
            alt="Dougie Analytics Logo"
            style={{
              width: '50px', // Adjust the width of the logo
              height: '50px', // Adjust the height of the logo
              objectFit: 'contain', // Make sure the logo fits well within the container
            }}
          />

          <div className="text-purple-800">
            <div className="brand text-4xl font-bold">Dougi Analytics</div> {/* Decreased font size */}
          </div>
        </div>

        <ul className="flex space-x-6"> {/* Align links next to the Dougie text */}
          <li><Link to="/" className="hover:text-indigo-500 text-lg">Home</Link></li> {/* Decreased font size */}
          <li><Link to="/about" className="hover:text-indigo-500 text-lg">About</Link></li> {/* Added About */}
          <li><Link to="/integration" className="hover:text-indigo-500 text-lg">Integration</Link></li>
          <li><Link to="/dashboard" className="hover:text-indigo-500 text-lg">Dashboard</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
