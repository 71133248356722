import React from 'react';
import { GoogleLogin } from '@react-oauth/google';  // Import GoogleLogin component

function UnderConstructionPage({ onLogin }) {  // Removed onSignUp prop
  // Determine if the app is running in production or development
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex flex-col items-center justify-center text-white">
      <section className="hero py-20 text-center">
        <h2 className="text-6xl font-extrabold mb-4">We're Under Construction</h2>
        <p className="text-xl mb-8">Our website is coming soon. Stay tuned for something amazing!</p>
        {isProduction ? (
          <div className="flex space-x-4">
            <GoogleLogin
              onSuccess={onLogin}  // Function to handle successful login
              onError={() => console.log('Login Failed')}  // Function to handle login failure
              ux_mode="popup"  // Can also use "redirect" for full-page redirect
            />
          </div>
        ) : (
          <div>
            <p>The site is under construction. You can explore without logging in during development.</p>
          </div>
        )}
      </section>
    </div>
  );
}

export default UnderConstructionPage;
