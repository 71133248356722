import React from 'react';

function Home() {
  return (
    <div className="home-page">
      <h1>Welcome to the Home Page</h1>
      <p>Explore the amazing features of our application.</p>
    </div>
  );
}

export default Home;
