import React from 'react';
import Navbar from './components/Navbar';  // Import the Navbar component

function HomePage({ user, onLogin, onLogout }) {  // Accept props for user, onLogin, and onLogout
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex flex-col items-center justify-center text-white">
      <Navbar user={user} onLogin={onLogin} onLogout={onLogout} />  {/* Pass the props to Navbar */}

      <section className="hero py-20 text-center">
        <h2 className="text-6xl font-extrabold mb-4">Welcome to my new website</h2>
        <p className="text-xl">Explore the amazing features and content</p>
      </section>
    </div>
  );
}

export default HomePage;
