// src/components/TopicSelector.js

import React from 'react';
import { Paper, List, ListItem, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  paper: {
    padding: '16px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '8px',
    marginBottom: '20px',
    width: '200px',
    fontFamily: 'Poppins, sans-serif',
  },
  title: {
    marginBottom: '16px',
    color: '#ffffff',
    fontFamily: 'Poppins, sans-serif',
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    fontFamily: 'Poppins, sans-serif',
  },
});

const TopicSelector = ({ topics, selectedTopic, onSelectTopic }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.title}>
        Select Topic
      </Typography>
      <List>
        {topics.map((topic) => (
          <ListItem
            key={topic}
            className={classes.listItem}
            selected={topic === selectedTopic}
            onClick={() => onSelectTopic(topic)}
          >
            <ListItemText primary={topic} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default TopicSelector;
