import React from 'react';
import Navbar from './components/Navbar'; // Import the Navbar component

const applications = [
    {
      name: 'Deco Room',
      image: '/images/decoroomdougie.png',
      description: 'Transform your room into a new style of your choice by uploading a picture and entering a prompt.',
    },
    {
      name: 'Ask Dougie',
      image: '/images/artificalintelligencedougie.jpg',
      description: 'Get answers to your questions from Dougie, your intelligent assistant.',
    },
    {
      name: 'Dougie Analytics',
      image: '/images/dataanalyticsdougie.jpeg',
      description: 'Analyze your data with Dougie Analytics, providing insightful visualizations and reports.',
    },
];

function Applications() {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex flex-col items-center justify-center text-white" style={{ fontFamily: 'Poppins, sans-serif' }}>
      <Navbar /> {/* Use the Navbar component */}
      
      {/* Description Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-extrabold mb-4 tracking-wide" style={{ fontFamily: 'Poppins, sans-serif' }}>
          Our Applications
        </h1>
        <p className="text-lg font-medium" style={{ fontFamily: 'Poppins, sans-serif' }}>
          Explore our range of applications designed to make your life easier and more fun.
        </p>
      </div>

      <div className="flex space-x-8">
        {applications.map((app, index) => (
          <div
            key={index}
            className="bg-white p-8 rounded-xl shadow-xl max-w-md text-center flex flex-col justify-between transition duration-500 hover:scale-105"
            style={{ height: '350px' }}  // Ensure a consistent height for all boxes
          >
            <div>
              <img 
                src={app.image} 
                alt={app.name} 
                className="w-32 mx-auto mb-6"
              />
              <p className="text-xl font-semibold mb-6 text-gray-900">
                {app.description}
              </p>
            </div>
            <div className="mt-auto">
              <button 
                className="bg-blue-600 text-white px-8 py-3 rounded-full font-bold text-lg hover:bg-blue-700 hover:shadow-lg transition duration-300 transform hover:scale-105"
              >
                {app.name}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Applications;
