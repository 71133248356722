import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Home from './Home';
import HomePage from './HomePage';
import DecoRoom from './DecoRoom';
import LLMModelPOC from './LLMModelPOC';
import Applications from './Applications';

import UnderConstructionPage from './UnderConstructionPage';
import Integration from './Integration';
import InstagramDataSelection from './InstagramDataSelection';
import InstagramSyncStatus from './InstagramSyncStatus';
import Navbar from './components/Navbar';  
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (credentialResponse) => {
    console.log(credentialResponse);
    setIsAuthenticated(true);
  };

  const handleSignUp = () => {
    setIsAuthenticated(true);
  };

  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <>
      {isProduction ? (
        <GoogleOAuthProvider clientId="882510010805-btkel9rpjbdmjo0q7sh5j3efdu23iiek.apps.googleusercontent.com">
          <div className="App">
            {isAuthenticated ? (
              <>
                <Navbar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/applications" element={<Applications />} />

                  <Route path="/deco_room" element={<DecoRoom />} />
                  <Route path="/llm_poc" element={<LLMModelPOC />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/integration" element={<Integration />} />
                  <Route path="/instagram-data-selection" element={<InstagramDataSelection />} />
                  <Route path="/instagram-sync-status" element={<InstagramSyncStatus />} />
                </Routes>
              </>
            ) : (
              <UnderConstructionPage onLogin={handleLogin} onSignUp={handleSignUp} />
            )}
          </div>
        </GoogleOAuthProvider>
      ) : (
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/deco_room" element={<DecoRoom />} />
            <Route path="/applications" element={<Applications />} />

            <Route path="/llm_poc" element={<LLMModelPOC />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/integration" element={<Integration />} />
            <Route path="/instagram-data-selection" element={<InstagramDataSelection />} />
            <Route path="/instagram-sync-status" element={<InstagramSyncStatus />} />
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
