import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Navbar from './components/Navbar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TopicSelector from './components/TopicSelector';

import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { PulseLoader } from 'react-spinners';
import './App.css';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const useStyles = makeStyles({
  dropdownButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#ffffff',
    textDecoration: 'none',
    padding: '10px',
    backgroundColor: '#4a4a4a',
    borderRadius: '5px',
    width: '100%',
    fontFamily: 'Poppins, sans-serif',
  },
  menuHeader: {
    fontWeight: 'bold',
    fontFamily: 'Poppins, sans-serif',
  },
  categoryTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#333',
    color: '#fff',
    cursor: 'pointer',
    borderBottom: '1px solid #444',
    fontFamily: 'Poppins, sans-serif',
  },
  categoryItem: {
    padding: '10px',
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#444',
    },
    fontFamily: 'Poppins, sans-serif',
  },
  scorecard: {
    padding: '16px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Transparent white background for each scorecard
    borderRadius: '8px',
    marginBottom: '16px',
    fontFamily: 'Poppins, sans-serif',
  },
  scorecardTitle: {
    color: '#333',
    marginBottom: '8px',
    fontFamily: 'Poppins, sans-serif',
  },
  scorecardValue: {
    fontSize: '1.7em',
    fontWeight: 'bold',
    color: '#000',
    fontFamily: 'Poppins, sans-serif',
  },
  scorecardCompare: {
    marginTop: '8px',
    color: '#888',
    fontSize: '0.9em',
    fontFamily: 'Poppins, sans-serif',
  },
  positiveChange: {
    color: '#4caf50',
    fontFamily: 'Poppins, sans-serif',
  },
  negativeChange: {
    color: '#f44336',
    fontFamily: 'Poppins, sans-serif',
  },
  layout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '20px',
    fontFamily: 'Poppins, sans-serif',
  },
  scorecardsContainer: {
    flex: 1,
    marginRight: '20px',
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Transparent white background
    borderRadius: '8px', // Optional: to match the scorecards' rounded corners
  },
  chartAndTableContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',  // Make the chart and table sit next to each other
    justifyContent: 'space-between',
    alignItems: 'stretch', // Make sure they align both top and bottom
    fontFamily: 'Poppins, sans-serif',
  },
  chartWrapper: {
    flex: 1,
    marginRight: '20px',
    fontFamily: 'Poppins, sans-serif',
    height: '400px', // Set a fixed height for the chart
  },
  tableWrapper: {
    flex: 1,
    fontFamily: 'Poppins, sans-serif',
    height: '400px', // Set the same fixed height for the table
    borderRadius: '8px', // Match the rounded corners of the scorecards
  },
  paperTransparent: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Transparent white background for the table
    boxShadow: 'none', // Remove the box shadow for full transparency
    padding: '16px',
    borderRadius: '8px',
  },
  tableContainerTransparent: {
    backgroundColor: 'rgba(255, 255, 255, 0) !important',
    boxShadow: 'none', // Remove the box shadow for full transparency
    borderRadius: '8px', // Force full transparency for the TableContainer
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '400px', // Fixed height to prevent layout shifting
    fontFamily: 'Poppins, sans-serif',
  },
});

const Dashboard = () => {
  const classes = useStyles();
  const [engagementKpis, setEngagementKpis] = useState([]);
  const [sessionsKpis, setSessionsKpis] = useState([]);
  const [avgSessionsKpis, setAvgSessionsKpis] = useState([]);
  const [sessionByChannel, setSessionByChannel] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPeriodType, setSelectedPeriodType] = useState('daily');
  const [selectedDate, setSelectedDate] = useState('');
  const [chartPeriodType, setChartPeriodType] = useState('daily');
  const [tablePeriodType, setTablePeriodType] = useState('daily');
  const [tableSelectedDate, setTableSelectedDate] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableAnchorEl, setTableAnchorEl] = useState(null);
  const [isPeriodTypeExpanded, setIsPeriodTypeExpanded] = useState(false);
  const [isDateExpanded, setIsDateExpanded] = useState(false);
  const [chartAnchorEl, setChartAnchorEl] = useState(null); // For New Users chart dropdown

  useEffect(() => {
    fetchKpis();
    fetchSessionByChannel();
  }, []);

  const fetchKpis = async () => {
    try {
      setLoading(true);
      const [engagementResponse, sessionsResponse, avgSessionsResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_URL}/kpis/website_engagement`),
        fetch(`${process.env.REACT_APP_API_URL}/kpis/website_total_sessions`),
        fetch(`${process.env.REACT_APP_API_URL}/kpis/website_analytics_avg_sessions`),
      ]);

      const engagementData = await engagementResponse.json();
      const sessionsData = await sessionsResponse.json();
      const avgSessionsData = await avgSessionsResponse.json();

      setEngagementKpis(engagementData.kpis || []);
      setSessionsKpis(sessionsData.kpis || []);
      setAvgSessionsKpis(avgSessionsData.kpis || []);

      const latestDate = engagementData.kpis[0]?.date || sessionsData.kpis[0]?.date || avgSessionsData.kpis[0]?.date || '';
      setSelectedDate(latestDate);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching KPIs:', error);
      setLoading(false);
    }
  };

  const fetchSessionByChannel = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/kpis/website_analytics_sessions_by_channel`);
      const data = await response.json();

      setSessionByChannel(data.kpis || []);

      const latestDate = data.kpis[0]?.date || '';
      setTableSelectedDate(latestDate);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching session by channel:', error);
      setLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPeriodTypeExpanded(false);
    setIsDateExpanded(false);
  };

  const handlePeriodTypeSelect = (type) => {
    setSelectedPeriodType(type);
    setSelectedDate('');
    setAnchorEl(null);
    setIsPeriodTypeExpanded(false);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setAnchorEl(null);
    setIsDateExpanded(false);
  };

  const togglePeriodType = () => {
    setIsPeriodTypeExpanded((prev) => !prev);
    if (isDateExpanded) setIsDateExpanded(false);
  };

  const toggleDate = () => {
    setIsDateExpanded((prev) => !prev);
    if (isPeriodTypeExpanded) setIsPeriodTypeExpanded(false);
  };

  const handleChartClick = (event) => {
    setChartAnchorEl(event.currentTarget);
  };

  const handleChartClose = () => {
    setChartAnchorEl(null);
  };

  const handleChartPeriodTypeSelect = (type) => {
    setChartPeriodType(type);
    setChartAnchorEl(null);
  };

  const handleTableClick = (event) => {
    setTableAnchorEl(event.currentTarget);
  };

  const handleTableClose = () => {
    setTableAnchorEl(null);
    setIsPeriodTypeExpanded(false);
    setIsDateExpanded(false);
  };

  const handleTablePeriodTypeSelect = (type) => {
    setTablePeriodType(type);
    setTableSelectedDate('');
    setTableAnchorEl(null);
    setIsPeriodTypeExpanded(false);
  };

  const handleTableDateSelect = (date) => {
    setTableSelectedDate(date);
    setTableAnchorEl(null);
    setIsDateExpanded(false);
  };

  const roundToTwoDecimals = (value) => {
    return value ? value.toFixed(2) : '0.00';
  };

  const getFilteredKpi = (kpis) => {
    return kpis.find(kpi => kpi.period_type === selectedPeriodType && kpi.date === selectedDate) || {};
  };

  const filteredEngagementKpi = getFilteredKpi(engagementKpis);
  const filteredSessionsKpi = getFilteredKpi(sessionsKpis);
  const filteredAvgSessionsKpi = getFilteredKpi(avgSessionsKpis);

  const getFilteredChannelKpis = () => {
    return sessionByChannel.filter(
      (kpi) => kpi.period_type === tablePeriodType && kpi.date === tableSelectedDate
    );
  };

  const getAvailableDates = () => {
    return [...new Set([
      ...engagementKpis.filter(kpi => kpi.period_type === selectedPeriodType).map(kpi => kpi.date),
      ...sessionsKpis.filter(kpi => kpi.period_type === selectedPeriodType).map(kpi => kpi.date),
      ...avgSessionsKpis.filter(kpi => kpi.period_type === selectedPeriodType).map(kpi => kpi.date),
    ])];
  };

  const getAvailableChannelDates = () => {
    return [...new Set(
      sessionByChannel.filter((kpi) => kpi.period_type === tablePeriodType).map((kpi) => kpi.date)
    )];
  };

  const newUsersChartData = {
    labels: sessionsKpis.filter(kpi => kpi.period_type === chartPeriodType).map(kpi => kpi.date),
    datasets: [
      {
        label: 'New Users Over Time',
        data: sessionsKpis.filter(kpi => kpi.period_type === chartPeriodType).map(kpi => kpi.value),
        fill: false,
        borderColor: '#4caf50',
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex flex-col items-center justify-center text-white p-4">
      <Navbar />

      <section className="hero py-20 text-center" style={{ marginTop: '100px' }}>  {/* Adjust this value as needed */}
        <Typography
          variant="h4"
          gutterBottom
          className="text-6xl font-extrabold mb-4"
          style={{ fontFamily: 'Poppins, sans-serif' }}
        >
          Dashboard
        </Typography>
        <Typography variant="body1" className="text-xl" style={{ fontFamily: 'Poppins, sans-serif' }}>
          Visualize your key performance indicators
        </Typography>
      </section>

      {loading ? (
        <div className={classes.loaderContainer}>
          <PulseLoader color="#ffffff" size={15} />
        </div>
      ) : (
        <Container>
          <div className={classes.layout}>
            <div className={classes.scorecardsContainer}>
              <Paper className="p-4 rounded-lg shadow-2xl" style={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}>
                <Typography variant="h6" gutterBottom>
                  Website Engagement
                </Typography>

                <a className={classes.dropdownButton} onClick={handleClick}>
                  <span className="date">
                    {`${selectedPeriodType.charAt(0).toUpperCase() + selectedPeriodType.slice(1)}, ${
                      selectedDate || 'Select Date'
                    }`}
                  </span>
                  <ArrowDropDownIcon />
                </a>
                <Menu id="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  <List component="nav">
                    <ListItem button onClick={togglePeriodType}>
                      <ListItemText primary="Period Type" />
                      {isPeriodTypeExpanded ? <ArrowUpwardIcon /> : <ArrowDropDownIcon />}
                    </ListItem>
                    <Collapse in={isPeriodTypeExpanded} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <MenuItem onClick={() => handlePeriodTypeSelect('daily')} className={classes.categoryItem}>
                          Daily
                        </MenuItem>
                        <MenuItem onClick={() => handlePeriodTypeSelect('weekly')} className={classes.categoryItem}>
                          Weekly
                        </MenuItem>
                      </List>
                    </Collapse>
                    <Divider />
                    <ListItem button onClick={toggleDate}>
                      <ListItemText primary="Date" />
                      {isDateExpanded ? <ArrowUpwardIcon /> : <ArrowDropDownIcon />}
                    </ListItem>
                    <Collapse in={isDateExpanded} timeout="auto" unmountOnExit>
                      {getAvailableDates().map((date) => (
                        <MenuItem key={date} onClick={() => handleDateSelect(date)} className={classes.categoryItem}>
                          {date}
                        </MenuItem>
                      ))}
                    </Collapse>
                  </List>
                </Menu>

                <div>
                  {filteredEngagementKpi.website_engagement !== undefined && (
                    <div className={classes.scorecard}>
                      <Typography variant="h6" className={classes.scorecardTitle}>
                        Engagement
                      </Typography>
                      <div className={classes.scorecardValue}>
                        {roundToTwoDecimals(filteredEngagementKpi.website_engagement)}%
                      </div>
                      <div className={classes.scorecardCompare}>
                        <span
                          className={
                            filteredEngagementKpi.percent_change_engagement >= 0
                              ? classes.positiveChange
                              : classes.negativeChange
                          }
                        >
                          {filteredEngagementKpi.percent_change_engagement >= 0 ? '▲' : '▼'}{' '}
                          {roundToTwoDecimals(Math.abs(filteredEngagementKpi.percent_change_engagement))}%
                        </span>
                          <small> vs previous period</small>
                      </div>
                    </div>
                  )}

                  {filteredSessionsKpi.value !== undefined && (
                    <div className={classes.scorecard}>
                      <Typography variant="h6" className={classes.scorecardTitle}>
                        Total Sessions
                      </Typography>
                      <div className={classes.scorecardValue}>
                        {roundToTwoDecimals(filteredSessionsKpi.value)}
                      </div>
                      <div className={classes.scorecardCompare}>
                        <span
                          className={
                            filteredSessionsKpi.percent_change >= 0
                              ? classes.positiveChange
                              : classes.negativeChange
                          }
                        >
                          {filteredSessionsKpi.percent_change >= 0 ? '▲' : '▼'}{' '}
                          {roundToTwoDecimals(Math.abs(filteredSessionsKpi.percent_change))}%
                        </span>
                        <small> vs previous period</small>
                      </div>
                    </div>
                  )}

                  {filteredAvgSessionsKpi.value !== undefined && (
                    <div className={classes.scorecard}>
                      <Typography variant="h6" className={classes.scorecardTitle}>
                        Avg Sessions
                      </Typography>
                      <div className={classes.scorecardValue}>
                        {roundToTwoDecimals(filteredAvgSessionsKpi.value)}
                      </div>
                      <div className={classes.scorecardCompare}>
                        <span
                          className={
                            filteredAvgSessionsKpi.percent_change >= 0
                              ? classes.positiveChange
                              : classes.negativeChange
                          }
                        >
                          {filteredAvgSessionsKpi.percent_change >= 0 ? '▲' : '▼'}{' '}
                          {roundToTwoDecimals(Math.abs(filteredAvgSessionsKpi.percent_change))}%
                        </span>
                        <small> vs previous period</small>
                      </div>
                    </div>
                  )}
                </div>
              </Paper>
            </div>

            <div className={classes.chartAndTableContainer}>
              <div className={classes.chartWrapper}>
                <Paper
                  className={classes.paperTransparent}
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    width: '380px',   // Set the width to match the graph
                    height: '280px',  // Set the height to match the graph
                    overflow: 'auto', // Ensure content is scrollable if it overflows
                  }}  >
                  <Typography variant="h6" gutterBottom>
                    New Users Over Time
                  </Typography>

                  <a className={classes.dropdownButton} onClick={handleChartClick}>
                    <span className="date">
                      {`${chartPeriodType.charAt(0).toUpperCase() + chartPeriodType.slice(1)}`}
                    </span>
                    <ArrowDropDownIcon />
                  </a>
                  <Menu
                    id="chart-menu"
                    anchorEl={chartAnchorEl}
                    keepMounted
                    open={Boolean(chartAnchorEl)}
                    onClose={handleChartClose}
                  >
                    <MenuItem onClick={() => handleChartPeriodTypeSelect('daily')} className={classes.categoryItem}>
                      Daily
                    </MenuItem>
                    <MenuItem onClick={() => handleChartPeriodTypeSelect('weekly')} className={classes.categoryItem}>
                      Weekly
                    </MenuItem>
                  </Menu>

                  <Line data={newUsersChartData} />
                </Paper>
              </div>

              <div className={classes.tableWrapper}>
                <Paper
                  className={classes.paperTransparent}
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    width: '380px',   // Set the width to match the graph
                    height: '280px',  // Set the height to match the graph
                    overflow: 'auto', // Ensure content is scrollable if it overflows
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Session by Channel
                  </Typography>

                  <a className={classes.dropdownButton} onClick={handleTableClick}>
                    <span className="date">
                      {`${tablePeriodType.charAt(0).toUpperCase() + tablePeriodType.slice(1)}`}
                    </span>
                    <ArrowDropDownIcon />
                  </a>
                  <Menu
                    id="table-menu"
                    anchorEl={tableAnchorEl}
                    keepMounted
                    open={Boolean(tableAnchorEl)}
                    onClose={handleTableClose}
                  >
                    <List component="nav">
                      <ListItem button onClick={() => setIsPeriodTypeExpanded(!isPeriodTypeExpanded)}>
                        <ListItemText primary="Period Type" />
                        {isPeriodTypeExpanded ? <ArrowUpwardIcon /> : <ArrowDropDownIcon />}
                      </ListItem>
                      <Collapse in={isPeriodTypeExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <MenuItem onClick={() => handleTablePeriodTypeSelect('daily')} className={classes.categoryItem}>
                            Daily
                          </MenuItem>
                          <MenuItem onClick={() => handleTablePeriodTypeSelect('weekly')} className={classes.categoryItem}>
                            Weekly
                          </MenuItem>
                        </List>
                      </Collapse>
                      <Divider />
                      <ListItem button onClick={() => setIsDateExpanded(!isDateExpanded)}>
                        <ListItemText primary="Date" />
                        {isDateExpanded ? <ArrowUpwardIcon /> : <ArrowDropDownIcon />}
                      </ListItem>
                      <Collapse in={isDateExpanded} timeout="auto" unmountOnExit>
                        {getAvailableChannelDates().map((date) => (
                          <MenuItem key={date} onClick={() => handleTableDateSelect(date)} className={classes.categoryItem}>
                            {date}
                          </MenuItem>
                        ))}
                      </Collapse>
                    </List>
                  </Menu>

                  <TableContainer className={classes.tableContainerTransparent}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Channel</TableCell>
                          <TableCell align="right">Value</TableCell>
                          <TableCell align="right">Percent Change</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getFilteredChannelKpis().map((row) => (
                          <TableRow key={row.channel}>
                            <TableCell component="th" scope="row">
                              {row.channel}
                            </TableCell>
                            <TableCell align="right">{roundToTwoDecimals(row.value)}</TableCell>
                            <TableCell align="right">
                              <span className={row.percent_change >= 0 ? classes.positiveChange : classes.negativeChange}>
                                {row.percent_change >= 0 ? '▲' : '▼'} {roundToTwoDecimals(Math.abs(row.percent_change))}%
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default Dashboard;
