import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';

const Integration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const instagramSynced = localStorage.getItem('isInstagramSynced') === 'true';
    const instagramDateTime = localStorage.getItem('connectedDateTime');
    const pinterestSynced = localStorage.getItem('isPinterestSynced') === 'true';
    const tiktokSynced = localStorage.getItem('isTikTokSynced') === 'true';
    const amazonSynced = localStorage.getItem('isAmazonSynced') === 'true';
    const amazonDateTime = localStorage.getItem('amazonSyncDateTime');
    const shopifySynced = localStorage.getItem('isShopifySynced') === 'true';
    const shopifyDateTime = localStorage.getItem('shopifySyncDateTime');
  }, []);

  const handleInstagramConnectClick = () => {
    console.log('Connecting to Instagram...');
    setTimeout(() => {
      navigate('/instagram-data-selection');
    }, 2000);
  };

  const handlePinterestConnectClick = () => {
    console.log('Connecting to Pinterest...');
    setTimeout(() => {
      navigate('/pinterest-data-selection');
    }, 2000);
  };

  const handleTikTokConnectClick = () => {
    console.log('Connecting to TikTok...');
    setTimeout(() => {
      navigate('/tiktok-data-selection');
    }, 2000);
  };

  const handleAmazonConnectClick = () => {
    console.log('Connecting to Amazon...');
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/amazon`;
  };

  const handleShopifyConnectClick = async () => {
    console.log('Connecting to Shopify...');
    try {
      const response = await fetch(`http://localhost:8000/auth/shopify?shop=dougianalytics`, {
        method: 'GET',
        redirect: 'follow',
        mode: 'cors',
        credentials: 'include', // Add this line if cookies are involved
      });

      if (response.redirected) {
        window.location.href = response.url;
      } else if (response.ok) {
        console.log('Connected to Shopify');
      } else {
        console.error('Error connecting to Shopify:', response.statusText);
      }
    } catch (error) {
      console.error('Error connecting to Shopify:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex flex-col items-center justify-start text-white overflow-auto" style={{ fontFamily: 'Poppins, sans-serif', paddingTop: '150px' }}>
      <Navbar />

      {/* Description Section */}
      <div className="text-center mb-12 mt-24 px-4 w-3/4">
        <h1 className="text-3xl font-extrabold mb-8 tracking-wide" style={{ fontFamily: 'Poppins, sans-serif', marginBottom: '20px' }}>
          One Place, One Version of Truth
        </h1>
        <p className="text-lg font-medium" style={{ fontFamily: 'Poppins, sans-serif', marginBottom: '20px' }}>
          Connect to 30+ datasets and streamline your data analysis.
        </p>
      </div>

      {/* Sales Channel Section */}
      <div className="w-full mb-12 mt-24">
        <h2 className="text-3xl font-bold mb-8 text-center">Sales Channel</h2>
        <div className="flex flex-wrap justify-center gap-8">
          {/* Shopify Integration */}
          <div className="bg-white p-6 rounded-xl shadow-xl w-64 text-center transform transition duration-500 hover:scale-105 h-80 flex flex-col justify-between">
            <img src="/images/shopify-logo.png" alt="Shopify" className="w-24 mx-auto mb-4" />
            <p className="text-lg font-semibold mb-4 text-gray-900">
              Integrate your Shopify store to analyze and optimize your sales performance.
            </p>
            <button
              onClick={handleShopifyConnectClick}
              className="bg-green-600 text-white px-6 py-2 rounded-full font-bold text-lg hover:bg-green-700 hover:shadow-lg transition duration-300 transform hover:scale-105"
            >
              Connect to Shopify
            </button>
          </div>

          {/* Amazon Integration */}
          <div className="bg-white p-6 rounded-xl shadow-xl w-64 text-center transform transition duration-500 hover:scale-105 h-80 flex flex-col justify-between">
            <img src="/images/amazon_logo.png" alt="Amazon" className="w-24 mx-auto mb-4" />
            <p className="text-lg font-semibold mb-4 text-gray-900">
              Integrate your Amazon Seller account to analyze and optimize your sales performance.
            </p>
            <button
              onClick={handleAmazonConnectClick}
              className="bg-blue-600 text-white px-6 py-2 rounded-full font-bold text-lg hover:bg-blue-700 hover:shadow-lg transition duration-300 transform hover:scale-105"
            >
              Connect to Amazon
            </button>
          </div>
        </div>
      </div>

      {/* Social Media Ads Section */}
      <div className="w-full mt-24 mb-12">
        <h2 className="text-3xl font-bold mb-8 text-center">Social Media Ads</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 justify-center">
          {/* Instagram Integration */}
          <div className="bg-white p-6 rounded-xl shadow-xl text-center transform transition duration-500 hover:scale-105 h-80 flex flex-col justify-between">
            <img src="/images/Instagram_icon.png.webp" alt="Instagram" className="w-24 mx-auto mb-4" />
            <p className="text-lg font-semibold mb-4 text-gray-900">
              Integrate your Instagram account to analyze and optimize your ad performance.
            </p>
            <button
              onClick={handleInstagramConnectClick}
              className="bg-blue-600 text-white px-6 py-2 rounded-full font-bold text-lg hover:bg-blue-700 hover:shadow-lg transition duration-300 transform hover:scale-105"
            >
              Connect to Instagram
            </button>
          </div>

          {/* Pinterest Integration */}
          <div className="bg-white p-6 rounded-xl shadow-xl text-center transform transition duration-500 hover:scale-105 h-80 flex flex-col justify-between">
            <img src="/images/Pinterest-logo.png" alt="Pinterest" className="w-24 mx-auto mb-4" />
            <p className="text-lg font-semibold mb-4 text-gray-900">
              Integrate your Pinterest account to analyze and optimize your ad performance.
            </p>
            <button
              onClick={handlePinterestConnectClick}
              className="bg-blue-600 text-white px-6 py-2 rounded-full font-bold text-lg hover:bg-blue-700 hover:shadow-lg transition duration-300 transform hover:scale-105"
            >
              Connect to Pinterest
            </button>
          </div>

          {/* TikTok Integration */}
          <div className="bg-white p-6 rounded-xl shadow-xl text-center transform transition duration-500 hover:scale-105 h-80 flex flex-col justify-between">
            <img src="/images/tiktok-icon-free-png.webp" alt="TikTok" className="w-24 mx-auto mb-4" />
            <p className="text-lg font-semibold mb-4 text-gray-900">
              Integrate your TikTok account to analyze and optimize your ad performance.
            </p>
            <button
              onClick={handleTikTokConnectClick}
              className="bg-blue-600 text-white px-6 py-2 rounded-full font-bold text-lg hover:bg-blue-700 hover:shadow-lg transition duration-300 transform hover:scale-105"
            >
              Connect to TikTok
            </button>
          </div>
        </div>
      </div>

      {/* Space after Social Media Ads Section */}
      <div className="mb-24"></div>
    </div>
  );
};

export default Integration;
