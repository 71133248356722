import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';

const InstagramDataSelection = () => {
  const [selectedData, setSelectedData] = useState([]);
  const navigate = useNavigate();

  const dataOptions = ['User Metrics', 'Media Metrics', 'Engagement Metrics'];

  const handleCheckboxChange = (data) => {
    setSelectedData((prev) => 
      prev.includes(data) ? prev.filter((item) => item !== data) : [...prev, data]
    );
  };

  const handleFetchData = () => {
    localStorage.setItem('selectedData', JSON.stringify(selectedData));
    navigate('/instagram-sync-status');  // Navigate to the status page after selection
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex flex-col items-center justify-center text-white">
      <Navbar />
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg text-center">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Select Data to Fetch</h2>
        {dataOptions.map((data) => (
          <div key={data} className="mb-4">
            <label className="text-gray-800">
              <input
                type="checkbox"
                value={data}
                onChange={() => handleCheckboxChange(data)}
                className="mr-2"
              />
              {data}
            </label>
          </div>
        ))}
        <button 
          onClick={handleFetchData} 
          className="bg-blue-600 text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300 mt-6"
        >
          Fetch Data
        </button>
      </div>
    </div>
  );
};

export default InstagramDataSelection;
