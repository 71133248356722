import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Navbar from './components/Navbar';  // Import the Navbar component

const CodeBlock = ({ language, value }) => {
  return (
    <div className="relative my-4 code-block">
      <SyntaxHighlighter language={language} style={materialDark}>
        {value}
      </SyntaxHighlighter>
      <button
        onClick={() => navigator.clipboard.writeText(value)}
        className="absolute top-2 right-2 bg-gray-700 text-white p-1 rounded"
      >
        Copy
      </button>
    </div>
  );
};

const apiUrl = process.env.REACT_APP_API_URL;

function LLMModelPOC() {
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [file, setFile] = useState(null);

  const handleSendMessage = async () => {
    if (!inputMessage.trim() && !file) return;

    const newMessages = [...chatMessages, { type: 'user', content: inputMessage }];
    setChatMessages(newMessages);
    setInputMessage('');

    const formData = new FormData();
    formData.append('message', inputMessage);
    if (file) formData.append('file', file);

    try {
      const response = await fetch(`${apiUrl}/chat`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const aiMessage = data.response.choices[0].message.content;

      setChatMessages([...newMessages, { type: 'ai', content: aiMessage }]);
      setFile(null);
    } catch (error) {
      console.error('Error fetching AI response:', error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const renderMessage = (message, index) => {
    return (
      <div key={index} className={`my-2 ${message.type === 'user' ? 'text-right' : 'text-left'}`}>
        <div className={`p-2 rounded-lg shadow-md inline-block max-w-full ${message.type === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}>
          <ReactMarkdown
            children={message.content}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <CodeBlock language={match[1]} value={String(children).replace(/\n$/, '')} />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              }
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex flex-col items-center justify-center text-white p-4">
      <Navbar />  {/* Use the Navbar component */}

      <section className="hero py-20 text-center">
        <h2 className="text-6xl font-extrabold mb-4">Welcome to Dougie's Data Playground</h2>
        <p className="text-xl">Upload your data and start asking questions</p>
      </section>

      <div className="container mx-auto px-4 py-8">
        <div id="chatContainer" className="chat-container bg-white p-4 rounded-lg shadow-2xl max-w-7xl w-full">
          <div className="chat-header bg-indigo-500 text-white p-2 rounded-t-lg flex justify-between">
            <span>Chat</span>
            <button onClick={() => setChatMessages([])}>Clear Chat</button>
          </div>
          <div className="chat-body flex-1 p-2 overflow-y-auto" style={{ maxHeight: '800px' }}>
            {chatMessages.map((msg, idx) => renderMessage(msg, idx))}
          </div>
          <div className="chat-input flex border-t border-gray-300 mt-2">
            <label htmlFor="fileInput" className="p-2 cursor-pointer">
              📎
            </label>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <input
              type="text"
              className="flex-1 p-2"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Type your question..."
            />
            <button onClick={handleSendMessage} className="bg-indigo-500 text-white p-2 rounded-r-lg">
              Send
            </button>
          </div>
          {file && (
            <div className="file-preview p-2 text-sm text-gray-800">
              <span>{file.name}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LLMModelPOC;
