import React, { useState } from 'react';
import Navbar from './components/Navbar';  // Import the Navbar component

const apiUrl = process.env.REACT_APP_API_URL;

function DecoRoom() {
  const [originalImage, setOriginalImage] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setOriginalImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.target);

    const response = await fetch(`${apiUrl}/generate_image`, {
      method: 'POST',
      body: formData
   });

    const result = await response.json();
    setLoading(false);

    if (result.generated_image_path) {
      // Prepend apiUrl to the image path
      const imageUrl = `${apiUrl}/${result.generated_image_path}?${new Date().getTime()}`;
      setGeneratedImage(imageUrl);
    } else {
      alert('Error generating image: ' + result.error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex flex-col items-center justify-center text-white p-4">
      <Navbar />  {/* Use the Navbar component */}
      
      <section className="hero py-20 text-center">
        <h2 className="text-6xl font-extrabold mb-4">Welcome to Deco Room</h2>
        <p className="text-xl">Upload a picture of your room and transform it into a new style of your choice!</p>
      </section>

      <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row gap-8">
        <div className="form-container bg-transparent p-8 rounded-lg shadow-2xl md:w-1/3 border border-gray-200">
          <form id="upload-form" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="room_image">Upload Room Image</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="file"
                name="room_image"
                id="room_image"
                required
                onChange={handleImageChange}
              />
            </div>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="prompt">Enter Prompt</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="prompt"
                id="prompt"
                placeholder="Describe the new room decor"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="room_type">Room Type</label>
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="room_type"
                id="room_type"
                required
              >
                <option value="Living Room">Living Room</option>
                <option value="Bedroom">Bedroom</option>
                <option value="Kitchen">Kitchen</option>
                <option value="Bathroom">Bathroom</option>
                <option value="Office">Office</option>
                <option value="Outdoor Patio">Outdoor Patio</option>
                <option value="Gaming Room">Gaming Room</option>
                <option value="Basement">Basement</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="style">Style</label>
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="style"
                id="style"
                required
              >
                <option value="Modern">Modern</option>
                <option value="Classic">Classic</option>
                <option value="Rustic">Rustic</option>
                <option value="Minimalist">Minimalist</option>
                <option value="Industrial">Industrial</option>
                <option value="Tropical">Tropical</option>
              </select>
            </div>
            <div className="flex items-center justify-center">
              <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Generate</button>
            </div>
          </form>
        </div>
        <div className="image-container mt-8 md:mt-0 md:flex-1 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div id="original-image-container" className="mb-4 md:mb-0">
            <h2 className="text-2xl font-bold text-center text-white mb-4">Original Image</h2>
            {originalImage ? (
              <img src={originalImage} alt="Original" className="max-w-full h-auto rounded shadow-md mx-auto transition-transform duration-300 transform hover:scale-105" />
            ) : (
              <p className="text-center text-gray-200">No image uploaded</p>
            )}
          </div>
          <div id="generated-image-container">
            <h2 className="text-2xl font-bold text-center text-white mb-4">Generated Image</h2>
            {loading ? (
              <div className="flex flex-col items-center">
                <div className="loader"></div>
                <p className="mt-4 text-center">Picture generation may take up to 30 seconds, please be patient.</p>
              </div>
            ) : generatedImage ? (
              <img src={generatedImage} alt="Generated" className="max-w-full h-auto rounded shadow-md mx-auto transition-transform duration-300 transform hover:scale-105" />
            ) : (
              <p className="text-center text-gray-200">No image generated</p>
            )}
          </div>
        </div>
      </div>

      <div className="testimonial mt-12 bg-white bg-opacity-90 p-8 rounded-lg shadow-2xl text-center">
        <p className="text-lg font-italic text-gray-800">"Deco Room transformed my living space beyond my expectations. Highly recommended!"</p>
        <h3 className="text-2xl font-bold mt-4">- Happy Customer</h3>
      </div>
    </div>
  );
}

export default DecoRoom;
